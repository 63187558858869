import React from 'react';

const GenderSelection = ({ setGender, setStep }) => {
  const genders = [
    {
      label: "Мужские",
      value: "Мужские",
      imageLink: "/img/man.jpg"
    },
    {
      label: "Женские",
      value: "Женские",
      imageLink: "/img/woman.jpg"
    }
  ];

  return (
    <div className="grid grid-cols-2 gap-4">
      {genders.map((gender, index) => (
        <div 
          key={index} 
          className="max-h-120 bg-white rounded-lg shadow-lg cursor-pointer hover:shadow-xl transition" // Увеличена максимальная высота
          onClick={() => { setGender(gender.value); setStep(2); }}
        >
          <img src={gender.imageLink} alt={gender.label} className="w-full h-80 object-cover object-center rounded-t-lg" />  {/* Увеличена высота */}
          <div className="p-4">
            <p className="text-center text-lg font-semibold">{gender.label}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GenderSelection;
