import React from 'react';

const DialColorSelection = ({ setDialColor }) => {
  const colors = ['Зеленый', 'Черный', 'Белый', 'Синий'];

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Выберите цвет циферблата</h2>
      <div className="flex gap-4">
        {colors.map((color, index) => (
          <button
            key={index}
            onClick={() => setDialColor(color)}
            className="bg-blue-500 text-white p-2 rounded"
          >
            {color}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DialColorSelection;
