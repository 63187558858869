import React from 'react';

function Layout({ children }) {
  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
              <a href="/"><img className="h-8 w-8 rounded-full" src="img/logo.jpeg" alt="Logo" /></a>


              </div>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline">
                  <a href="#" className="px-3 py-2 rounded-md text-sm font-medium text-gray-900 bg-gray-200">Конструктор</a>
                  <a href="#" className="ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50">Магазин</a>
                  <a href="#" className="ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50">Отследить заказ</a>
                  {/* More items */}
                </div>
              </div>
            </div>
            {/* More items */}
          </div>
        </div>
      </nav>
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <nav className="text-sm text-gray-600">
            <a href="#" className="hover:underline">Главная</a>
            <span className="mx-2">/</span>
            <a href="#" className="hover:underline">Категория</a>
            </nav>
          <h1 className="text-3xl font-bold text-gray-900">
            Конструктор уникальных дизайнов часов 
          </h1>

        </div>
        
      </header>
      <main className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {children}
        </div>
      </main>
      
      <footer className="bg-white-800 text-gray py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <p className="text-center text-sm">
            &copy; SABR
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Layout;
