import React, { useState } from 'react';

const LogoNameSelection = ({ setLogoName }) => {
  const [name, setName] = useState("");

  const handleInputChange = (e) => {
    if (e.target.value.length <= 7) {
      setName(e.target.value);
      setLogoName(e.target.value);
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Введите имя на лого</h2>
      <input
        type="text"
        value={name}
        onChange={handleInputChange}
        className="border p-2 rounded"
      />
    </div>
  );
};

export default LogoNameSelection;
