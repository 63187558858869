import React, { useState,useEffect } from 'react';
import CorpusSelection from './components/CorpusSelection';
import GenderSelection from './components/GenderSelection';
import DialColorSelection from './components/DialColorSelection';
import MoonTextSelection from './components/MoonTextSelection';
import LogoNameSelection from './components/LogoNameSelection';
import Preview from './components/Preview';
import Layout from './components/Layout';
import { ChevronRightIcon,ChevronLeftIcon } from '@heroicons/react/24/solid';


function App() {
  const [step, setStep] = useState(1);
  const [gender, setGender] = useState("");
  const [corpus, setCorpus] = useState("");
  const [dialColor, setDialColor] = useState("");
  const [moonText, setMoonText] = useState("");
  const [logoName, setLogoName] = useState("");

  useEffect(() => {

    if(gender)
      console.log("Текущий выбранный gender:", gender);

    if(corpus){
      console.log("Текущий выбранный корпус:", corpus);
      setStep(3); // Переход на следующий шаг после выбора корпуса
    }

    if(dialColor){
      console.log("Текущий выбранный dialColor:", dialColor);
      setStep(4); // Переход на следующий шаг после выбора корпуса
    }
    if(moonText){
      console.log("Текущий выбранный moonText:", moonText);
      setStep(5); // Переход на следующий шаг после выбора корпуса
    }
    if(logoName){
      console.log("Текущий выбранный logoName:", logoName);
      setStep(5); // Переход на следующий шаг после выбора корпуса
    }
  }, [corpus,gender,dialColor,moonText,logoName]);
  
  

  return (
    <Layout>
    <div className="App bg-white rounded shadow p-4">
      {step === 1 && (
        <GenderSelection setGender={setGender} setStep={setStep} />
      )}

      {step === 2 && <CorpusSelection setCorpus={setCorpus} gender={gender} />}

      {step === 3 && <DialColorSelection setDialColor={setDialColor} />}

      {step === 4 && <MoonTextSelection setMoonText={setMoonText} />}

      {step === 5 && <LogoNameSelection setLogoName={setLogoName} />}

      {step > 1 && (
        <button onClick={() => setStep(step - 1)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 my-4 px-4 rounded inline-flex items-center">
          <ChevronLeftIcon className="w-5 h-5 mr-2" />
          <span>Назад</span>
        </button>

      )}

      {step < 5 && (


        <button onClick={() => setStep(step + 1)} className="bg-gray-500 hover:bg-blue-700 text-white font-bold py-2 my-4 px-4 rounded inline-flex items-center">
          <span>Далее</span>
          <ChevronRightIcon className="w-5 h-5 ml-2" />
        </button>


      )}

      {step === 5 && (
        <Preview
          gender={gender}
          corpus={corpus}
          dialColor={dialColor}
          moonText={moonText}
          logoName={logoName}
        />
      )}
    </div>
    </Layout>
  );
}

export default App;
