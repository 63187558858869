import React from 'react';

const MoonTextSelection = ({ setMoonText }) => {
  const moonTexts = [
    'Текст 1',
    'Текст 2',
    // ... другие варианты
  ];

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Выберите текст на полумесяце</h2>
      <div className="flex gap-4">
        {moonTexts.map((text, index) => (
          <button
            key={index}
            onClick={() => setMoonText(text)}
            className="bg-blue-500 text-white p-2 rounded"
          >
            {text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MoonTextSelection;
