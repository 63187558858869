import React from 'react';

const Preview = ({ corpus, dialColor, moonText, logoName }) => {
  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Предварительный просмотр</h2>
      <div className="border p-4 rounded shadow-lg">
        <p>Корпус: {corpus?.articleNumber || 'Не выбрано'}</p>
        <p>Тип: {corpus?.type || 'Не выбрано'}</p>
        <p>Цвет циферблата: {dialColor || 'Не выбрано'}</p>
        <p>Текст на полумесяце: {moonText || 'Не выбрано'}</p>
        <p>Имя на лого: {logoName || 'Не выбрано'}</p>
        {/* Здесь будет ваше итоговое изображение. Для демонстрации я использую placeholder. */}
        <div className="mt-4">
          <img src="img/img1.png" alt="Итоговое изображение" className="mx-auto"/>
        </div>
      </div>
    </div>
  );
};

export default Preview;
