import React from 'react';

const CorpusSelection = ({ setCorpus }) => {
  const corpora = [
    {
      "articleNumber": 1,
      "type": "муж",
      "diskColor": "графитовый",
      "circleType": "круг",
      "circleColor": "черный с серебряными ромбами",
      "nameOnDial": "sabr",
      "nameColor": "черный + золото",
      "imageLink": "img/img1.png"
    },
    {
      "articleNumber": 2,
      "type": "муж",
      "diskColor": "зеленый",
      "circleType": "круг",
      "circleColor": "черный с серебряными ромбами",
      "nameOnDial": "sabr",
      "nameColor": "черный + золото",
      "imageLink": "img/img1.png"
    },
    {
      "articleNumber": 3,
      "type": "муж",
      "diskColor": "графитовый",
      "circleType": "круг",
      "circleColor": "черный с серебряными ромбами",
      "nameOnDial": "sabr",
      "nameColor": "хром + хром",
      "imageLink": "img/img1.png"
    },
    {
      "articleNumber": 4,
      "type": "муж",
      "diskColor": "зеленый",
      "circleType": "круг",
      "circleColor": "черный с серебряными ромбами",
      "nameOnDial": "sabr",
      "nameColor": "хром + хром",
      "imageLink": "img/img1.png"
    },
    {
      "articleNumber": 5,
      "type": "муж",
      "diskColor": "зеленый",
      "circleType": "полумесяц",
      "circleColor": "золото",
      "nameOnDial": "имя",
      "nameColor": "черный градиент / золото",
      "imageLink": "img/img1.png"
    },
    {
      "articleNumber": 6,
      "type": "муж",
      "diskColor": "зеленый",
      "circleType": "полумесяц",
      "circleColor": "хром",
      "nameOnDial": "имя",
      "nameColor": "черный градиент / хром",
      "imageLink": "img/img1.png"
    },
    {
      "articleNumber": 7,
      "type": "муж",
      "diskColor": "зеленый",
      "circleType": "полумесяц",
      "circleColor": "черный",
      "nameOnDial": "имя",
      "nameColor": "черный градиент / хром",
      "imageLink": "img/img1.png"
    },
    {
      "articleNumber": 51,
      "type": "муж",
      "diskColor": "жемчужный",
      "circleType": "полумесяц",
      "circleColor": "золото",
      "nameOnDial": "имя",
      "nameColor": "черный градиент / золото",
      "imageLink": "img/img1.png"
    },
    {
      "articleNumber": 52,
      "type": "муж",
      "diskColor": "графитовый",
      "circleType": "полумесяц",
      "circleColor": "золото",
      "nameOnDial": "имя",
      "nameColor": "черный градиент / золото",
      "imageLink": "img/img1.png"
    },
    {
      "articleNumber": 62,
      "type": "муж",
      "diskColor": "графитовый",
      "circleType": "полумесяц",
      "circleColor": "серебро",
      "nameOnDial": null,
      "nameColor": null,
      "imageLink": "img/img1.png"
    },
    {
      "articleNumber": 71,
      "type": "муж",
      "diskColor": "жемчужный",
      "circleType": "полумесяц",
      "circleColor": "черный",
      "nameOnDial": "имя",
      "nameColor": "черный градиент / хром",
      "imageLink": "img/img1.png"
    },
    {
      "articleNumber": 72,
      "type": "муж",
      "diskColor": "графитовый",
      "circleType": "полумесяц",
      "circleColor": "черный",
      "nameOnDial": "имя",
      "nameColor": "черный градиент / хром",
      "imageLink": "img/img1.png"
    }
  ];  

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Выберите тип корпуса</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {corpora.map((corpus, index) => (
          <div
            key={index}
            onClick={() => { 
              setCorpus(corpus); 
            }}
            className="cursor-pointer border p-4 hover:shadow-lg rounded transition duration-200 ease-in-out"
          >
            <img src={corpus.imageLink} alt={`Арт. ${corpus.articleNumber}`} className="mx-auto mb-2 max-h-72"/>
            <p className="text-center mb-1 font-semibold">Арт. {corpus.articleNumber}</p>
            <p className="text-xs text-gray-500">{corpus.diskColor}, {corpus.circleColor}</p>
            <p className="text-xs text-gray-500">{corpus.nameOnDial}, {corpus.nameColor}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CorpusSelection;
